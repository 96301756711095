.icon {
  @apply w-1.83 h-1.83 bg-contain mr-0.33;
}

.item-title {
  @apply flex leading-none text-1.33 font-bold mb-1.33 text-34 items-center;
}

.item-body-rounded {
  @apply rounded-1.67 p-1.33 mb-2.5 border-0.08 border-242;
}

.item-body-rounded-style {
  @apply py-1.33 flex flex-wrap justify-between h-21.5 flex-col overflow-auto whitespace-nowrap;
}

.option {
  @apply px-1.33 h-3.17 flex justify-center items-center flex-1 text-card;
}

.option-space {
  @apply h-3.17 flex justify-center items-center w-0.08 text-201;
}

.option-active {
  @apply bg-white rounded-0.33 font-bold;
}

.ratio-icon {
  @apply inline-block rounded-0.42 border-0.25 border-201 mr-0.33;
}

.result-item {
  @apply py-1.17 px-1.33 rounded-1 mt-1 bg-white flex items-center;
}

.result-item-left {
  @apply w-7 flex justify-between text-34 font-bold;
}

.btn-item {
  @apply text-black flex flex-1 justify-center font-bold items-center;
}

.delete-dialog .adm-dialog-action-row {
  border: 0;
}

.delete-dialog .adm-dialog-action-row {
  padding: 1.67rem;
  padding-top: 0;
}

.delete-dialog .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  line-height: 1.2;
  border: 0.04rem solid rgba(180, 186, 192, 1);
  border-radius: 2rem;
  color: rgba(150, 153, 161, 1);
  font-weight: 500;
  font-size: 1.25rem;
}

.delete-dialog
  .adm-dialog-footer
  .adm-dialog-action-row
  > .adm-dialog-button:last-child {
  margin-left: 1.08rem;
  background-color: rgba(255, 75, 135, 1);
  border-color: rgba(255, 75, 135, 1);
  color: #fff;
}

.delete-dialog .adm-dialog-button:before {
  border: 0;
}

.delete-dialog .adm-auto-center-content {
  color: rgba(150, 153, 161, 1);
}

.my-tabs .adm-tabs-header {
  border-bottom: 0;
}

.my-tabs.with .adm-tabs-tab-active {
  color: rgba(105, 75, 255, 1);
}

.my-tabs.ai_beauty .adm-tabs-tab-active {
  color: rgba(76, 166, 255, 1);
}

.my-tabs .adm-tabs-tab-line {
  background: rgba(105, 75, 255, 1);
  border-radius: 0.42rem;
}

.my-tabs.with .adm-tabs-tab-line {
  background: rgba(105, 75, 255, 1);
}

.my-tabs.ai_beauty .adm-tabs-tab-line {
  background: rgba(76, 166, 255, 1);
}

.my-tabs .adm-tabs-tab {
  padding-bottom: 2px;
  padding-top: 0;
}

.photo-swiper .adm-page-indicator-dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

.photo-swiper .adm-page-indicator-dot.adm-page-indicator-dot-active {
  width: 31px;
  background: #fff;
}

.photo-result-shadow {
  box-shadow: 0rem 0.42rem 1.25rem 0rem rgba(0, 0, 0, 0.2);
}

.repair-result-shadow {
  box-shadow: 0rem 0.42rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0rem 0.42rem 1.25rem 0rem rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.my-photo-tabs .adm-tabs-header-mask-left {
  left: 0;
  background: linear-gradient(
    to right,
    rgb(246 247 249),
    rgba(255, 255, 255, 0)
  );
}

.my-photo-tabs .adm-tabs-header-mask-right {
  right: 0;
  background: linear-gradient(
    to left,
    rgb(246 247 249),
    rgba(255, 255, 255, 0)
  );
}

.compare-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.17rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 49%,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes pointOutAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 0.8;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes pointInAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.point {
  width: 40px;
  height: 40px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  border-radius: 20px;
  margin-left: -20px;
  margin-top: -20px;
}

.point.out {
  animation: pointOutAnimation 2s infinite;
}

.point.in {
  animation: pointInAnimation 2s infinite;
}

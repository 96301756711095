@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
  font-size: 12px;
}

body {
  @apply bg-bg h-full text-1.17;
}

body.dark {
  @apply bg-bg_dark;
}

#root {
  @apply h-full flex flex-col;
}

.adm-toast-icon svg {
  display: inline;
}
body, div {
  -webkit-touch-callout: none; /* 禁止长按文本弹出菜单(iOS) */
  -webkit-user-select: none; /* 禁止选择文本(iOS) */
}

.paint-slider svg {
  display: inline;
  vertical-align: baseline;
}
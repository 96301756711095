.item {
    @apply bg-item w-6.25 text-1.33 h-8.33 flex justify-center items-center rounded-0.67 font-bold relative overflow-hidden border-0.17; border-color: transparent;
}
.item.active {
    border-color:#FFC300;
}
.item .top{
    position: absolute;
    left: 0;
    top:0;
    background: linear-gradient(174deg, #FFDF77 0%, #FFC300 100%);
    height: 1.67rem;
    width: 100%;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    font-size: .83rem;
    line-height: 1.67rem;
}
table{
    width: 100%;
}

td{
    width: 33.33%;
    color: rgba(255, 255, 255, 0.70);
    border: 1px solid rgba(255, 195, 0, 0.40);
    text-align: center;
    padding: 1.17rem 0 ;
    font-size: 1.17rem;

}
td.th{
    color: #fff;
    font-weight: bold;
}
.title{
    margin-top: 4.17rem;
    margin-bottom: 2.08rem;
    @apply text-center text-1.67;
    background-image: url("https://sloth-oss.oss-cn-beijing.aliyuncs.com/miniprogram/dialogue/titlebar.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 center;
    font-weight: bold;
}